const Config = require('../config')

module.exports = function (lang, route, uid) {
    const prefix = Config.Lang.PathByLocale[lang]
    const path = Config.Lang.RouteByLocale[route][lang]

    if (uid === undefined) {
        uid = ''
    }

    return `/${prefix}/${path}/${uid}`
}
