function Filters(allLabel) {
    this.collection = [];
    this.results = [];
    this.applied = {};
    this.allLabel = allLabel;
}

Filters.prototype.setCollection = function(collection) {
    if (this.collection.length > 0) {
        return
    }

    var artists = {}
    var techniques = {}
    var years = []

    collection.forEach((artwork) => {
        const data = artwork.node.data

        if (data.artist) {
            artists[data.artist.uid] = data.artist.document.data.name.text
        }
        if (data.technique) {
            techniques[data.technique.uid] = data.technique.document.data.name.text
        }
        if (data.year) {
            years.push(data.year)
        }
    })

    this.collection = collection
    this.artists = this.toKVArray(artists)
    this.techniques = this.toKVArray(techniques)

    // Parse decades from years
    var decades = {};
    var minYear = Math.min(...years)
    var maxYear = Math.max(...years)

    minYear = parseInt(minYear / 10) * 10
    for (var i = minYear; i <= maxYear; i += 10) {
        decades[i] = i + '-' + (i + 9) % 100;
    }

    this.years = this.toKVArray(decades)
}

Filters.prototype.toKVArray = function(o) {
    var a = [{
        value: '',
        label: this.allLabel,
    }];
    for (var k in o) {
        a.push({
            value: k,
            label: o[k],
        })
    }
    return a
}

Filters.prototype.apply = function(key, value) {
    this.applied[key] = value

    var applied = this.applied;
    this.results = []

    this.collection.forEach((artwork) => {
        const data = artwork.node.data

        if (applied.artist && applied.artist !== data.artist.uid) {
            return
        }
        if (applied.technique && applied.technique !== data.technique.uid) {
            return
        }
        if (applied.year) {
            var minYear = parseInt(applied.year)
            var maxYear = minYear + 9
            if (data.year < minYear || data.year > maxYear) {
                return
            }
        }

        if (applied.search) {
            const index = [
                data.title.text,
                data.description.text,
                data.artist.uid ? data.artist.document.data.name.text : "",
                data.technique.uid ? data.technique.document.data.name.text : "",
                data.year,
            ].join('|').toLocaleLowerCase()

            var match = true
            applied.search.toLocaleLowerCase().split(' ').forEach((term) => {
                match = match && index.includes(term)
            })

            if (!match) {
                return
            }
        }

        this.results.push(artwork)
    })
}

module.exports = Filters
